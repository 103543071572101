<template>
  <b-modal
    v-if="component && component.context && component.context.details"
    id="allocate-function-modal"
    :title="`Allocate ${component.context.details.name}`"
    size="xl"
    class="p-0"
    @show="onShow"
    @ok="allocateFn()"
  >
    <div>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Select Performers:" label-for="perf-select">
            <b-form-select
              id="perf-select"
              v-model="fields.selected_performers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Select Enablers:" label-for="enabler-select">
            <b-form-select
              id="enabler-select"
              v-model="fields.selected_enablers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Select Beneficiaries:" label-for="beneficiary-select">
            <b-form-select
              id="beneficiary-select"
              v-model="fields.selected_beneficiaries"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Select Related Objectives:" label-for="objective-select">
            <b-form-select
              id="objective-select"
              v-model="fields.selected_objectives"
              :options="objective_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Perform in accordance with Standards:" label-for="standards-select">
            <b-form-select
              id="standards-select"
              v-model="fields.selected_standards"
              :options="standard_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Other Allocations:" label-for="other-select">
            <b-form-select
              id="other-select"
              v-model="fields.selected_allocations"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Allocate
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AllocateFn',
  data() {
    return {
      fields: {
        selected_performers: [],
        selected_objectives: [],
        selected_standards: [],
        selected_enablers: [],
        selected_beneficiaries: [],
        selected_allocations: [],
      },
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      performers: state => state.domainModel.performers,
      functions: state => state.domainModel.functions,
      objectives: state => state.domainModel.objectives,
      standards: state => state.domainModel.standards,
    }),
    component() {
      return this.selected_entity
    },
    performer_opts() {
      return this.performers.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    objective_opts() {
      return this.objectives.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    standard_opts() {
      return this.standards.map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected_entity(newVal) {
      console.debug('For ', newVal)
      const t = this.fields
      t.selected_performers = newVal.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source)
      t.selected_beneficiaries = newVal.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.target)
      t.selected_enablers = newVal.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.target)
      t.selected_objectives = newVal.context.relations.edges.filter(e => e.name === 'Contributes to').map(x => x.target)
      t.selected_standards = newVal.context.relations.edges.filter(e => e.name === 'Performed iaw').map(x => x.target)
      t.selected_allocations = newVal.context.relations.edges.filter(e => e.name === 'Allocated to').map(x => x.target)
    },
  },
  methods: {
    onShow() {
      this.$store.dispatch('domainModel/getComponents')
    },
    allocateFn() {
      console.debug('Calling domainModel/allocateFunction', this.fields)
      this.$store.dispatch('domainModel/allocateFunction', {
        function: this.component.context.details.id,
        performers: this.fields.selected_performers,
        enablers: this.fields.selected_enablers,
        beneficiaries: this.fields.selected_beneficiaries,
        objectives: this.fields.selected_objectives,
        standards: this.fields.selected_standards,
        other: this.fields.selected_allocations,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.component.context.details.name} Allocated`,
            icon: 'CheckIcon',
            text: 'Functional Allocation Performed',
            variant: 'success',
          },
        })
        this.$bvModal.hide('allocate-function-modal')
      })
    },
  },
}
</script>
