<template>
  <div v-if="items">
    <h5 v-if="!hideHeader" :class="{'pl-2': true, 'pt-2': items.length}" style="display: inline-block">
      {{ items.length ? items.length : 'No' }} {{ titlePre }}
    </h5>
    <b-button
      v-if="modalId"
      v-b-popover.top.hover="buttonHoverText"
      style="float: right"
      variant="outline-secondary"
      size="sm"
      :class="{'mr-2': true, 'mt-1': items.length}"
      @click="$bvModal.show(modalId)"
    >
      <feather-icon :icon="buttonIcon" />
    </b-button>

    <div v-if="items.length">
      <hr v-if="!hideHeader" class="mb-0">
      <slot name="above-list-group" :items="items" />
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-customizer-area scroll-area"
      >
        <b-list-group style="max-height: 50vh" flush>
          <slot name="list-group" :items="items" />
        </b-list-group>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'DisplayListGroup',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    titlePre: {
      type: String,
      default: 'Items',
    },
    items: {
      type: Array,
      required: true,
    },
    modalId: {
      type: String,
      default: null,
    },
    buttonIcon: {
      type: String,
      default: 'LinkIcon',
    },
    buttonHoverText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
  }),
  computed: {
    title() {
      if (this.items.length) return `${this.items.length} ${this.titlePre}`
      return `No ${this.titlePre}`
    },
  },
}
</script>

<style scoped>
.card {
    margin: 0
}
</style>
