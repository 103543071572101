import { createHeaderShape } from '@/components/Domain/OntologyTreeViewJoint/ontologyShapes';
import { v4 as uuidv4 } from "uuid/dist/esm-node";
const enableLogging = false;
function log() {
    if (enableLogging) {
        console.log(...arguments);
    }
}
export function useJointJsTree(treeOptions) {
    const { nodeTransformFn, nodeFilterFn, nodeHideFn, createShape, addLink, pageSize } = treeOptions;
    let rootNodeId = "";
    const treeData = { nodes: [], edges: [], rootId: "" };
    const nodeMap = {};
    let initialLoad = true;
    /*
     * 1. afterLoadTree()
     * Populates the treeData before parsing and rendering
     * params: result - Should contain tree data result directly from API call
     */
    function afterLoadTree(result) {
        treeData.nodes = result.nodes;
        treeData.edges = result.edges;
        treeData.rootId = result.root;
    }
    /* 2. createTree()
     * create the nodeMap, assumes afterLoadTree has been called already
     */
    async function createTree(initialLoad = false) {
        // create the nodeMap, assumes afterLoadTree has been called already
        treeData.nodes.forEach(node => {
            node._children = [];
            if (!nodeFilterFn || nodeFilterFn(node)) {
                // data might be getting re-loaded, check if node already exists
                if (nodeMap[node.id]) {
                    node._hidden = nodeMap[node.id]._hidden;
                }
                nodeMap[node.id] = node;
            }
        });
        // populate _children arrays
        treeData.edges.forEach(e => {
            const child = nodeMap[e.target];
            if (child && e.source && nodeMap[e.source]) {
                child.pid = e.source;
                nodeMap[e.source]._children.push(child.id);
                if (initialLoad) {
                    nodeMap[e.source]._hidden = false;
                }
            }
        });
        if (initialLoad) {
            treeData.nodes.forEach(node => {
                if (nodeMap[node.id]) {
                    if (nodeHideFn && node.id !== treeData.rootId) {
                        // used for initial hiding of nodes, which the user can then un-hide
                        // Also, can't hide the root
                        node._hidden = nodeHideFn(node);
                        nodeMap[node.id] = node;
                    }
                }
            });
        }
        rootNodeId = treeData.rootId;
        setDescendantCounts(treeData.rootId);
    }
    /*
     * 3. redrawNodes()
     * Create the visible jointJs shapes.
     * Does NOT add them to graph (resetCells), so client code can have more control
     */
    async function redrawNodes(rootNodeId = null) {
        const allCells = [];
        function drawTree(nodeId) {
            const currentNode = nodeMap[nodeId];
            if (!currentNode || currentNode._hidden) {
                return;
            }
            allCells.push(createShape(nodeTransformFn(currentNode)));
            const childCount = currentNode._children.length;
            currentNode._children.sort((a, b) => nodeMap[a].name.localeCompare(nodeMap[b].name));
            let index = 0;
            let currentParent = nodeId;
            longestPrefixLength = 0;
            currentNode._children.forEach(childId => {
                [currentParent, index] = handleAddHeaders(childCount, currentParent, index, nodeId, allCells, childId);
                const source = nodeMap[currentParent];
                const target = nodeMap[childId];
                if (source && target && !(source._hidden || target._hidden)) {
                    allCells.push(addLink(source, target));
                }
                if (nodeId !== currentParent) {
                    nodeMap[currentParent]._descendantCount = nodeMap[currentParent]._children.length;
                }
                drawTree(childId);
            });
        }
        drawTree(rootNodeId || treeData.rootId);
        return allCells;
    }
    async function refreshTree() {
        await createTree();
        await redrawNodes();
    }
    /*
     * Cache the count of each node's descendants, depth-first
     * Assume _children have already been populated
     * a + c + 1 = (accumulator + currentChildDescendants + 1 (1 for this node)
     */
    function setDescendantCounts(rootId) {
        function _setDescendantCounts(id) {
            const count = nodeMap[id]._children
                .map(ch => _setDescendantCounts(ch))
                .reduce((a, c) => a + c + 1, 0);
            nodeMap[id]._descendantCount = count;
            return count;
        }
        _setDescendantCounts(rootId);
    }
    function walkTree(nodeId, filterFn, mapFn) {
        return nodeMap[nodeId]._children.filter(n => filterFn(n)).map(n => {
            walkTree(n, filterFn, mapFn);
            return mapFn(n);
        });
    }
    /*
     * Show (unhide) a node and its parent and ancestral line up to the root node
     * Used for when the search or focus finds a node that is currently hidden
     */
    async function showAllAncestors(id) {
        let cur = id;
        let isUnhidden = false;
        const results = [];
        for (let i = 0; i < 1000; i++) {
            results.push(cur);
            if (!nodeMap[cur] || nodeMap[cur].pid == null) {
                break;
            }
            if (nodeMap[cur]._hidden) {
                isUnhidden = true;
                nodeMap[cur]._hidden = false;
            }
            cur = nodeMap[cur].pid;
        }
        // Only refresh the graph if some nodes need to be unhidden
        if (isUnhidden) {
            await redrawNodes();
        }
        return [results, isUnhidden];
    }
    function _getRangeBoundsNames(nodeId, index) {
        let name1 = 'Aaaaa';
        let name2 = 'Zzzzz';
        try {
            name1 = nodeMap[nodeMap[nodeId]._children[index]].name;
        }
        catch {
        }
        try {
            const children = nodeMap[nodeId]._children;
            const outerBound = Math.min(index + pageSize, children.length);
            name2 = nodeMap[children[outerBound - 1]].name;
        }
        catch {
        }
        return { name1, name2 };
    }
    let longestPrefixLength = 0;
    /* getAlphabeticRangeText
    * boundsNames: name1 and name2 from nodeMap, from the already-alphabetised children array.
    * Iterates through the characters of the first and last names to provide node headers
    * Break early for efficiency, as soon as the characters are different
    * If too many characters are the same, remove these same characters and only display them once
    */
    function getAlphabeticRangeText(boundsNames) {
        const RANGE_TEXT_LENGTH = 5;
        const MAX_NODE_NAME_LENGTH = 256;
        let text = 'A to Z';
        let { name1, name2 } = boundsNames;
        let start = 0;
        for (let i = 1; i < MAX_NODE_NAME_LENGTH; i++) {
            let length = i;
            if (longestPrefixLength > i) {
                length = longestPrefixLength;
            }
            if (length > RANGE_TEXT_LENGTH)
                length = RANGE_TEXT_LENGTH;
            if (start > 0)
                length = 1;
            const prefix1 = name1.substring(start, start + length);
            const prefix2 = name2.substring(start, start + length);
            if (i > RANGE_TEXT_LENGTH) {
                text = `(${name1.substring(0, start)}) ${prefix1} to ${prefix2}`;
                start += 1;
            }
            else {
                text = `${prefix1} to ${prefix2}`;
            }
            if (prefix1 !== prefix2) {
                if (i > longestPrefixLength) {
                    longestPrefixLength = i;
                }
                break;
            }
        }
        return text;
    }
    function addSingleHeader(index, currentParent, childCount, nodeId, allCells) {
        if (index === 0 || index % pageSize === 0) {
            // insert a new parent
            currentParent = uuidv4();
            // const text = `${index + 1} to ${Math.min(index + pageSize, childCount)} of ${childCount}`
            let text = getAlphabeticRangeText(_getRangeBoundsNames(nodeId, index));
            const hn = {
                id: currentParent, text, heading: nodeMap[nodeId]['display_name'], isHeader: true,
                nodeType: 'header',
                pid: nodeId, _children: [],
            };
            nodeMap[currentParent] = hn;
            allCells.push(createHeaderShape(hn.id, hn.text, hn.heading));
            allCells.push(addLink(nodeMap[nodeId], nodeMap[currentParent]));
        }
        return currentParent;
    }
    function handleAddHeaders(childCount, currentParent, index, nodeId, allCells, childId) {
        if (childCount >= pageSize) {
            // handle adding headers (fake pagination nodes)
            currentParent = addSingleHeader(index, currentParent, childCount, nodeId, allCells);
            index++;
            nodeMap[childId].pid = currentParent; // pid = parent Id
            nodeMap[currentParent]._children.push(childId);
        }
        return [currentParent, index];
    }
    return { nodeMap, treeData, showAllAncestors, afterLoadTree, createTree, refreshTree, redrawNodes };
}
