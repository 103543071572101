import { shapes, util } from '@clientio/rappid';
const markup = [
    {
        tagName: 'rect',
        selector: 'body',
    },
    {
        tagName: 'foreignObject',
        selector: 'bodyText',
        children: [
            {
                tagName: 'div',
                namespaceURI: 'http://www.w3.org/1999/xhtml',
                selector: 'bodyTextContent',
                class: 'nodeText',
                style: {
                    height: '100%',
                    'font-weight': 'bold',
                    padding: '2rem 2.5rem 2rem 2rem',
                    'text-align': 'center',
                    'overflow-wrap': 'anywhere',
                },
            }
        ]
    },
    {
        tagName: 'text',
        selector: 'header',
    },
];
// noinspection JSAnnotator
export class OntologyNodeShape extends shapes.basic.Rect {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.OntologyNodeShape',
            size: { width: 300, height: 125 },
            attrs: {
                body: {
                    width: 300,
                    height: 125,
                    rx: 10,
                    ry: 10,
                    strokeWidth: 2,
                    stroke: 'black',
                    magnet: true
                },
                header: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'left',
                    x: 10,
                    y: 20,
                    fontSize: 11,
                    fill: 'black',
                },
                bodyTextContent: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    x: 150,
                    y: 65,
                    fontSize: 72,
                    fill: 'black',
                },
                bodyText: {
                    width: 290,
                    height: 110,
                    fontSize: 18,
                    x: 6,
                    y: 6
                }
            },
        };
    }
    markup = markup;
}
Object.assign(shapes, {
    komp: {
        OntologyNodeShape,
    },
});
export function createShape(node) {
    let wrappedHeaderText = '';
    if (!node.labels) {
        console.log('node with no labels', node);
    }
    else {
        const labels = node?.labels.filter(l => l !== 'Component' && !l.startsWith('RBAC_')).join(', ');
        wrappedHeaderText = node ? labels : '';
    }
    const wrappedBodyText = util.sanitizeHTML(node?.display_name);
    const options = {
        position: { x: 0, y: 0 },
        attrs: {
            bodyTextContent: {
                title: wrappedBodyText,
                html: wrappedBodyText,
            },
            body: {
                class: node.abstract ? 'ont-abstract' : 'ont-node',
                stroke: node.abstract ? 'white' : 'black',
                strokeDasharray: node.abstract ? '4 2' : '1 2',
            },
            header: {
                text: wrappedHeaderText,
            },
        },
        id: node?.id || Math.random(),
    };
    let shape = null;
    if (node) {
        shape = new OntologyNodeShape(options);
        shape.attr('body/strokeDasharray', node.abstract ? '4 2' : '');
        if (node.buildConfig) {
            shape.attr('body/class', shape.attr('body/class') + ` ont-bc-${node.buildConfig}`);
        }
        let fontSize = Math.max(42 - (wrappedBodyText.length), 12);
        shape.attr('bodyText/fontSize', fontSize);
    }
    return shape;
}
export function createHeaderShape(id, text, heading) {
    const wrappedBodyText = text;
    const options = {
        position: { x: 0, y: 0 },
        attrs: {
            bodyTextContent: {
                title: wrappedBodyText,
                // html: `<p>${wrappedBodyText}</p><button onclick="show('${id}')">Show</button>`
                //     + `<button onclick="hide('${id}')">Hide</button>`,
                html: wrappedBodyText,
            },
            body: {
                class: 'ont-header',
                stroke: 'white',
            },
            header: {
                text: heading,
            },
        },
        id: id,
    };
    const shape = new OntologyNodeShape(options);
    shape.resize(300, 125);
    shape.attr('header/fontSize', 14);
    return shape;
}
