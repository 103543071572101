<template>
  <b-modal
    id="import-uml-modal"
    title="Import Ontology from UML XMI"
    size="lg"
    @shown="onShow"
    @ok="importFile"
  >
    <div>
      <p class="font-medium-1 mt-0">
        Select UML XMI file to import
      </p>
      <b-form-file
        v-model="file"
        size="lg"
        accept=".xml, .xmi"
        invalid-feedback="test"
        :state="Boolean(file)"
        @input="fileLinked()"
      />
      <h4 class="text-primary">
        File Contents
      </h4>
      <pre>
        <code>
          {{ fileText }}
        </code>
      </pre>
    </div>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'ImportUMLModal',
  components: {
  },
  data() {
    return {
      file: null,
      fileText: '',
    }
  },
  methods: {
    onShow() {
      this.fileText = ''
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        this.fileText = 'File APIs are not fully supported in this browser!'
      } else {
        const reader = new FileReader()
        console.log(this.file)
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            this.fileText = evt.target.result
          }
        } else {
          this.fileText = ''
        }
      }
    },
    importFile() {
      const params = {
        model: this.$store.state.model.id,
        xml: this.fileText,
      }
      axiosIns.post('/api/v2/domain_model/import_static_uml', params)
        .then(console.log)
        .catch(console.error)
    },
  },
}
</script>

<style scoped>
pre {
  height: 300px;
  overflow: auto;
}
code {
    height: 300px;
    overflow: auto;
  }
</style>
