import { dia, ui, shapes } from '@clientio/rappid';
import { createXrefLink } from "@/views/Behaviour/JointJSGraph/CustomShapes/customShapeFunctions";
export class PaperService {
    paper;
    scroller;
    createPaper(graph, paperOverrides = {}) {
        const defaultLink = createXrefLink(null, null, 'orange');
        const asyncTreeRendering = localStorage.getItem('asyncTreeRendering') === "true";
        const defaultOptions = {
            model: graph,
            background: {
                color: 'rgba(0, 0, 0, 0)',
            },
            width: 1600,
            height: 600,
            gridSize: 50,
            cellViewNamespace: shapes,
            frozen: false,
            async: asyncTreeRendering,
            interactive: false,
            labelsLayer: true,
            clickThreshold: 10,
            defaultLink,
        };
        if (asyncTreeRendering) {
            defaultOptions.viewport = view => {
                const model = view.model;
                if (!model || !model.getBBox) {
                    // something has a bad model, just render it
                    return true;
                }
                const bbox = model.getBBox();
                const viewportRect = this.scroller.getVisibleArea();
                if (model.isLink()) {
                    // vertical/horizontal links have zero width/height
                    // we need to manually inflate the bounding box
                    bbox.inflate(1);
                }
                return !!viewportRect.intersect(bbox);
            };
        }
        const paperOptions = { ...defaultOptions, ...paperOverrides };
        this.paper = new dia.Paper(paperOptions);
        // MUST HAVE SCROLLER TO WORK IN VUE
        this.scroller = new ui.PaperScroller({
            paper: this.paper,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true,
        });
        // The Paper options object accepts this viewport: function
        // This should only be used when async is true
        function viewport(view) {
            console.log('not used');
        }
        this.scroller.render();
        return { paper: this.paper, scroller: this.scroller };
    }
}
