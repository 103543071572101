<template>
  <b-list-group-item hover>
    {{ id }}
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItemEntities',
  props: {
    id: {
      required: true,
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
