import { ui } from "@clientio/rappid";
export class MoveMenuService {
    contextElement;
    createMoveMenu(node, x, y) {
        this.contextElement = node;
        const contextToolbar = new ui.ContextToolbar({
            target: { x, y }, // this can be a HTMLElement, not a jointjs Element!
            vertical: true,
            anchor: 'top',
            scale: 1,
            tools: [
                { action: 'moveAgg', content: 'Move as aggregate child' },
                { action: 'moveSub', content: 'Move as sub-type' },
                { action: 'copyAgg', content: 'Copy as aggregate child' },
                { action: 'copySub', content: 'Copy as sub-type' },
                { action: 'merge', content: 'Merge...' },
            ],
        });
        contextToolbar.render();
        return contextToolbar;
    }
}
