<template>
  <b-modal
    v-if="selected_entity2 && selected_entity2.context"
    id="import-subtree-modal"
    :key="key"
    title="Import Subtree from CSV"
    :size="modalSize" sel
    hide-footer
    @show="onShow"
    @close="key++"
  >
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      style="box-shadow: none"
      :finish-button-text="finishButtonText"
      back-button-text="Previous"
      @on-complete="importSubtree"
    >
      <!-- Step One : Select file -->
      <tab-content
        title="Select File"
        icon="feather icon-file-text"
        :before-change="fileValidator"
      >
        <p class="font-medium-1 mt-0">
          Select CSV file to import
        </p>
        <b-form-file
          v-model="file"
          size="lg"
          accept=".csv"
          invalid-feedback="test"
          :state="Boolean(file)"
          @input="fileLinked()"
        />
      </tab-content>

      <!-- Step Three : Map CSV columns to Kompozition properties -->
      <tab-content
        title="Mapping"
        icon="feather icon-link"
      >
        <div>
          <b-row>
            <b-col>
              <label for="import-stereotype">Stereotype:</label>
              <b-form-select id="import-stereotype" v-model="labels" :options="stereotypes" multiple />
            </b-col>
            <b-col>
              <label for="import-parent-rel">Stereotype:</label>
              <b-form-select id="import-parent-rel" v-model="parent_rel" :options="parent_rel_opts" />
            </b-col>
          </b-row>
        </div>
        <hr>
        <div v-if="file">
          <b-row>
            <b-col>
              <p class="text-primary font-weight-bold font-medium-4 text-center">
                CSV Column
              </p>
            </b-col>
            <b-col>
              <p class="text-primary font-weight-bold font-medium-4 text-center">
                Kompozition Property
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-50">
              <b-form-checkbox
                v-model="has_header_row"
                switch
                @change="autoBind"
              >
                Has header row
              </b-form-checkbox>
            </b-col>
          </b-row>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettingsMappings"
            style="max-height: 30vh"
          >
            <b-row v-for="({ property, column}, index) in fields" :key="`columns-${index}`" class="mb-1">
              <!-- CSV Data Columns -->
              <b-col>
                <b-input-group>
                  <b-input-group-append v-if="!mandatory_properties.includes(property)">
                    <b-button
                      variant="flat-danger"
                      @click="deleteBinding(property)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="cursor-pointer text-danger"
                      />
                    </b-button>
                  </b-input-group-append>
                  <b-form-select :value="column" :options="columns" @input="val => fields[index].column = val" />
                </b-input-group>
              </b-col>

              <!-- Kompozition Property-->
              <b-col>
                <h5 v-if="mandatory_properties.includes(property)" class="text-capitalize">
                  {{ property }}
                </h5>
                <b-form-select
                  v-else
                  :value="property"
                  class="text-capitalize"
                  :options="properties"
                  :disabled="mandatory_properties.includes(property)"
                  @input="val => fields[index].property = val"
                />
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>

          <b-button variant="flat-success" size="sm" block @click="addBinding">
            Add property mapping <feather-icon icon="PlusIcon" />
          </b-button>
          <hr>
          <div class="mt-2">
            <span class="font-medium-2 font-weight-bold">Preview</span>
          </div>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            style="max-height: 40vh"
          >
            <b-table
              :items="entities.slice(0,5)"
            />
          </vue-perfect-scrollbar>
        </div>
      </tab-content>
    </form-wizard>

  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import csv from 'jquery-csv'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ImportSubtree',
  components: {
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      file: null,
      csv_array: [],
      has_header_row: true,
      specification: null,
      hidden_properties: [],
      mandatory_properties: ['entity', 'parent'],
      entity_fields: ['entity', 'parent', 'ref_id', 'description', 'type'], // TODO:  Make this dynamic based on Graph Schema - similar to requirement attributes
      bulk_create_loading: false,
      modalSize: 'lg',
      fields: [
        {
          property: 'entity',
          column: null,
        },
        {
          property: 'parent',
          column: null,
        },
      ],
      key: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      perfectScrollbarSettingsMappings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
        suppressScrollX: true,
      },
      labels: [],
      parent_rel: 'aggregation',
      parent_rel_opts: [
        { value: 'aggregation', text: 'Aggregate' },
        { value: 'inheritance', text: 'Inherit' },
      ],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
    }),
    properties() {
      return this.entity_fields.filter(field => !this.hidden_properties.includes(field)).map(field => Object({
        value: field,
        text: field.replaceAll('_', ' '),
        disabled: this.fields.map(({ property }) => property).includes(field),
      }))
    },
    columns() {
      const mapFunction = (text, value) => (this.has_header_row ? { value, text } : { value, text: `Column - ${value}` })
      if (!this.csv_array[0]) return []
      return [{ value: null, text: 'No Value' }, ...this.csv_array[0].map(mapFunction)]
    },
    entities() {
      const subtree = []
      this.csv_array.forEach((row, index) => {
        if (this.has_header_row && index === 0) { return }
        const entity = {}
        this.fields.forEach(({ property, column }) => {
          entity[property] = row[column] || null
        })
        subtree.push({ ...entity })
      })
      return subtree
    },
    finishButtonText() {
      if (this.bulk_create_loading) {
        return 'Adding Subtree...'
      }
      return `Add Subtree (${this.entities.length} Entities)`
    },
  },
  mounted() {
    this.labels = this.selected_entity2?.context?.details.labels
    this.parent_rel = 'aggregation'
  },
  methods: {
    ...mapActions({
      bulkAddRequirements_vuex: 'requirements/bulkAddRequirement',
    }),
    onShow() {
      // this.getRequirementAttributes()
    },
    fileValidator() {
      return new Promise((resolve, reject) => {
        if (this.file) {
          return resolve(true)
        }
        return reject()
      })
    },
    changeModalSize() {
      this.modalSize = 'xl'
      return true
    },
    importSubtree() {
      this.bulk_create_loading = true
      if (this.labels.indexOf('Component') === -1) {
        this.labels.unshift('Component')
      }
      const payload = {
        model: this.$store.state.model.id,
        entities: this.entities,
        root: this.selected_entity2.context.details.id,
        labels: this.labels,
        parent_rel: this.parent_rel,
      }
      console.log('Payload: ', payload)
      this.$http.post('/api/v2/domain_model/import_subtree', payload)
        .then(({ data }) => {
          this.$bvModal.hide('import-subtree-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Subtree Added',
              icon: 'CheckIcon',
              text: `${this.entities.length} Entities Added in Subtree`,
              variant: 'success',
            },
          })
          this.bulk_create_loading = false
          this.$emit('imported', data)
        })
    },
    autoBind() {
      if (this.has_header_row) {
        const props = this.properties.filter(({ value }) => !this.fields.filter(({ column }) => column).map(({ property }) => property).includes(value))
        this.columns.forEach(col => {
          const prop = props.find(({ text }) => col.text.toLowerCase() === text.toLowerCase())
          if (prop) {
            const existingProp = this.fields.find(({ property }) => property === prop.value)
            if (existingProp) {
              this.fields.find(({ property }) => property === prop.value).column = col.value
            } else {
              this.fields.push({
                property: prop.value,
                column: col.value,
              })
            }
          }
        })
      }
    },
    addBinding() {
      const nextProperty = this.entity_fields.filter(field => !this.hidden_properties.includes(field)).find(field => !this.fields.map(({ property }) => property).includes(field))
      this.fields.push({
        property: nextProperty,
        column: null,
      })
    },
    deleteBinding(delProperty) {
      this.fields = this.fields.filter(({ property }) => property !== delProperty)
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        alert('The File APIs are not fully supported in this browser!')
      } else {
        const reader = new FileReader()
        console.log('File: ', this.file)
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            this.csv_array = csv.toArrays(evt.target.result)
            if (this.has_header_row && this.csv_array[0]) {
              const newFields = this.csv_array[0].filter(colNm => !this.entity_fields.includes(colNm.toLowerCase())).map(x => x.toLowerCase())
              this.entity_fields = [...this.entity_fields, ...newFields]
              this.autoBind()
            }
          }
        } else {
          this.csv_array = []
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
