import coreService from '@/libs/api-services/core-service';
import store from '@/store';
export const keySets = [
    {
        name: 'node',
        title: 'Node Type',
        prefix: 'ont-',
        modal: '',
        nodeProperty: 'nodeType',
        items: [
            'Node',
            'Abstract',
            'Header',
        ],
    },
    {
        name: 'buildconfig',
        title: 'Configuration',
        prefix: 'ont-bc-',
        modal: 'build-config-picker-modal',
        nodeProperty: 'config',
        transformData: async (modalResult) => await coreService.componentApi.getComponentBuildConfigs(store.state.model.lookup.Performers, modalResult),
        resultMapper: (node, data) => {
            const nwc = data.find(nwc => nwc.id === node.id);
            let level = 'none';
            if (nwc) {
                if (nwc.directConfig.length > 0) {
                    return 'full';
                }
                else if (nwc.configCount > 0) {
                    if (nwc.configCount < nwc.childCount) {
                        return 'partial';
                    }
                    else if (nwc.configCount === nwc.childCount) {
                        return 'full';
                    }
                }
            }
            return 'none';
        },
        items: [
            {
                name: 'none',
                description: 'Not in Config',
                explanation: 'These entities and their immediate children are not in any of the selected configurations',
            },
            {
                name: 'Partial',
                description: 'Partial',
                explanation: 'These entities have some immediate children that are in at least one of the selected configurations',
            },
            {
                name: 'Full',
                description: 'Full',
                explanation: 'These entities or all of their immediate children are in at least one of the selected configurations',
            },
        ],
    },
];
