<template>
  <b-modal
    v-if="selectedEntity && selectedEntity.context"
    id="merge-entity-modal"
    :title="`Merge ${selectedEntity.context.details.name}`"
    size="lg"
    class="p-0"
    @show="onShow"
    @ok="mergeEntity"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Select Entity to Merge with" label-for="parent-select">
            <b-form-input placeholder="Filter..." v-model="filterText"></b-form-input>
            <b-form-select
              id="parent-select"
              v-model="selectedParent"
              :options="parentOpts"
              :select-size="12"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="primary" :disabled="!selectedParent" @click="ok()">
        Merge
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'MergeEntityModal',
  props: ['descendants', 'mergeToNode'],
  setup(props, context) {
    const filterText = ref('')
    const selectedParent = ref(false)
    const selectedEntity = computed(() => store.state.domainModel.selected_entity2)
    const components = computed(() => store.state.domainModel.components)
    const parentOpts = computed(() => components.value
      .filter(cpt => (filterText.value === '' || cpt.qualified_name?.toLowerCase().includes(filterText.value.toLowerCase()))
        && cpt.id !== selectedEntity.value?.context.details.id
        && !props.descendants.includes(cpt.id))
      .map(x => ({
        value: x.id,
        text: x.qualified_name,
      })))

    async function onShow() {
      await store.dispatch('domainModel/getComponents')
      if (props.mergeToNode) {
        filterText.value = components.value.find(c => c.id === props.mergeToNode)?.qualified_name || ''
        selectedParent.value = parentOpts.value.find(po => po.value === props.mergeToNode)
      }
    }

    function mergeEntity() {
      store.dispatch('domainModel/mergeComponent', {
        from: selectedEntity.value.context.details.id,
        to: selectedParent.value,
      })
        .then(() => {
          context.emit('merged', selectedParent.value)
        })
    }

    return {
      selectedParent,
      selectedEntity,
      parentOpts,
      components,
      filterText,
      onShow,
      mergeEntity,
    }
  },
}
</script>
