<template>
  <div class="resize-handle" @mousedown.prevent="initResize" />
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  name: 'ResizeBar',
  props: {
    sidebarId: {
      type: String,
      required: true,
    },
    minWidth: {
      type: Number,
      default: 450,
    },
  },
  setup(props, context) {
    const contextMenuWidth = ref()
    onMounted(() => {
      contextMenuWidth.value = localStorage.getItem(`${props.sidebarId}Width`) || props.minWidth
    })

    watch(() => contextMenuWidth.value,
      width => {
        document.getElementById(props.sidebarId).style.width = `${width}px`
      })

    function initResize(event) {
      contextMenuWidth.value = parseInt(document.getElementById(props.sidebarId).style.width, 10)
      window.addEventListener('mousemove', startResizing)
      window.addEventListener('mouseup', stopResizing)
    }

    function startResizing(event) {
      const currentSidebarWidth = parseInt(document.getElementById(props.sidebarId).style.width, 10)
      const bbox = document.getElementById(props.sidebarId).getBoundingClientRect()
      const newWidth = currentSidebarWidth - (event.clientX - bbox.left)
      contextMenuWidth.value = Math.min(Math.max(newWidth, props.minWidth), window.screen.availWidth * 0.75)
      localStorage.setItem(`${props.sidebarId}Width`, `${contextMenuWidth.value}`)
    }

    function stopResizing(event) {
      window.removeEventListener('mousemove', startResizing)
      window.removeEventListener('mouseup', stopResizing)
    }

    return {
      initResize,
    }
  },
}
</script>
<style scoped lang="scss">
.resize-handle {
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px; /* Width of the handle */
  cursor: ew-resize; /* Horizontal resize cursor */
}

.resize-handle:hover {
  border-left: 2px solid rgb(133, 184, 255);
}
</style>
