import { computed, onMounted, ref } from '@vue/composition-api';
import coreService from '@/libs/api-services/core-service';
import axiosIns from '@/libs/axios';
import Fuse from 'fuse.js';
import { behaviourNodeStyleDefinitions } from '@/views/Behaviour/JointJSGraph/behaviourNode';
export default {
    props: {
        btId: { type: String, required: true },
        // the map of behaviourNodes already loaded by the current BT view
        nodeMap: { type: Object, required: true },
        // the API call to get all the objects of objectType associated with each BN in the BT
        // the type of associated objects e.g. "Requirement" or "TestCase"
        objectType: { type: String, required: true },
        // e.g. name or display_id
        objectNameProperty: { type: String, required: true, default: () => 'name' },
        // e.g. text or object_text
        objectTextProperty: { type: String, required: false, default: () => 'object_text' },
    },
    setup(props, context) {
        const associatedItems = ref([]);
        const filterText = ref('');
        const isLoading = ref(false);
        const filteredAssociatedItems = computed(() => {
            let results = [];
            if (filterText.value === '') {
                results = associatedItems.value;
            }
            else {
                const options = {
                    includeScore: true,
                    keys: ['display_id', 'description', 'object_text', props.objectNameProperty, props.objectTextProperty],
                };
                const list = associatedItems.value;
                const fuse = new Fuse(list, options);
                results = fuse.search(filterText.value).filter(e => e.score < 0.1).map(e => e.item);
            }
            if (results && results.length > 0) {
                // emit the flattened results back to the BT for showing the colours on the nodes
                const flattened = results.map(n => n.behaviours).reduce((a, b) => a.concat(b));
                const nodeSet = [...new Set(flattened)];
                Object.values(props.nodeMap).forEach(n => {
                    const node = n;
                    node.search = '';
                });
                nodeSet.forEach(n => {
                    props.nodeMap[n].search = 'found';
                });
                context.emit('foundBehaviourNodes', nodeSet);
            }
            // these results are for the sidebar
            return results;
        });
        onMounted(async () => {
            await onShow();
        });
        async function onShow() {
            isLoading.value = true;
            try {
                if (props.objectType === 'Requirement') {
                    associatedItems.value = await coreService.behavioursApi.getBtRequirements(props.btId);
                }
                if (props.objectType === 'TestCase') {
                    associatedItems.value = await coreService.behavioursApi.getBtTestCases(props.btId);
                }
            }
            catch (e) {
                console.error('Error loading search sidebar', e);
            }
            finally {
                isLoading.value = false;
            }
        }
        function getNodeCss(node) {
            const style = behaviourNodeStyleDefinitions.find(s => s.title === node.type);
            return style ? style.cssClass : `bn-${node.type.toLocaleLowerCase()}`;
        }
        function nodeFocus(nodeId) {
            if (nodeId) {
                context.emit('nodeFocus', nodeId);
            }
        }
        async function deleteLink(nodeId, requirement) {
            const msg = `Are you sure you want to disassociate behaviour node: '${props.nodeMap[nodeId].name}' from requirement '${requirement.display_id}'?`;
            try {
                const yesNo = await context.root.$bvModal.msgBoxConfirm(msg, {
                    title: 'Disassociate Requirement',
                    okVariant: 'danger',
                    okTitle: 'Remove association',
                    cancelTitle: 'Dismiss',
                    cancelVariant: 'outline-secondary',
                });
                if (!yesNo)
                    return;
                axiosIns
                    .post(`/api/v2/behaviour/disassociate_bn_requirement/${nodeId}`, {
                    req: requirement.id,
                })
                    .finally(() => { onShow(); });
            }
            catch (e) {
                console.log(e);
            }
        }
        return {
            associatedItems,
            nodeFocus,
            deleteLink,
            filteredAssociatedItems,
            filterText,
            isLoading,
            getNodeCss,
        };
    },
};
