<template>
  <b-modal
    v-if="component && component.context && component.context.details"
    id="generate-qa-requirements-modal"
    :title="`Generate Quality Attribute Requirements for ${component.context.details.name}`"
    size="xl"
    class="p-0"
    @ok="generateRequirements()"
  >
    <div>
      <GenerateRequirementFormLite
        v-model="fields"
        :component="component"
        type="functional"
      />
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button :disabled="!fields.dest_target" variant="primary" @click="ok()">
        Generate
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GenerateRequirementFormLite from '@/components/Domain/Forms/GenerateRequirementFormLite.vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'GenerateQualityAttributeRequirements',
  components: {
    GenerateRequirementFormLite,
  },
  data() {
    return {
      fields: {
        type: 'story',
        specification: null,
        dest_target: null,
        with_children: true,
        direction: 'child',
      },
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    component() {
      return this.selected_entity
    },
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected_entity(newVal) {
      const t = this.fields
    },
  },
  methods: {
    generateRequirements() {
      console.debug('Calling domainModel/generateQualityAttributeRequirements', this.fields)
      this.$store.dispatch('domainModel/generateQualityAttributeRequirements', {
        cpt: this.component.context.details.id,
        dest_spec: this.fields.specification,
        dest_target: this.fields.dest_target,
        direction: this.fields.direction,
        type: this.fields.type,
        with_children: this.fields.with_children,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Quality attribute requirements generated for ${this.component.context.details.name} in ${this.fields.specification}`,
            icon: 'CheckIcon',
            text: 'Quality Attribute Requirements Generated',
            variant: 'success',
          },
        })
        this.$bvModal.hide('generate-qa-requirements-modal')
      })
    },
  },
}
</script>

<style scoped>

</style>
