import { renderMenu } from '@/components/Generic/Graph/services/contextMenuHelper';
export class ContextToolbarService {
    contextElement;
    createContextMenu(node, parentElement, lastX, lastY) {
        this.contextElement = node;
        const tools = [
            { action: 'addChild', content: 'Add Child' },
            { action: 'delete', content: 'Delete Entity' },
            { action: 'addInstance', content: 'Add Instance' },
            { action: 'merge', content: 'Merge...' },
            { action: 'move', content: 'Move...' },
            { action: 'copy', content: 'Copy...' },
            { action: 'makeAttr', content: 'Make Attribute...' },
            { action: 'levelUp', content: 'Go to Parent' },
            { action: 'showOnly', content: 'Show as Root' },
            { action: 'classDiagram', content: 'Show as Class Diagram' },
            { action: 'importSubtree', content: 'Import subtree on node' },
            { action: 'exportSubtree', content: 'Export subtree to JSON' },
            { action: 'generateFN', content: 'Generate Requirements/Stories/Features' },
            { action: 'generateQA', content: 'Generate Quality Attribute Requirements' },
        ];
        return renderMenu(tools, lastX, lastY);
    }
}
