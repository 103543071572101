import { ui } from "@clientio/rappid";
export class HaloService {
    contextElement;
    linkAddCallback;
    hideChildrenCallback;
    halo;
    graph;
    createHalo(node, paper, graph, linkAddCallback, hideChildrenCallback, childCount) {
        this.contextElement = node;
        this.graph = graph;
        this.linkAddCallback = linkAddCallback;
        this.hideChildrenCallback = hideChildrenCallback;
        // Render the halo
        if (this.halo) {
            this.halo.remove();
        }
        const cellView = paper.findViewByModel(node);
        const halo = new ui.Halo({
            cellView,
            boxContent: false,
            clearAll: false,
            clearOnBlankPointerdown: false,
            useModelGeometry: true,
        });
        halo.removeHandle('resize');
        halo.removeHandle('rotate');
        halo.removeHandle('remove');
        halo.removeHandle('fork');
        halo.removeHandle('unlink');
        halo.removeHandle('clone');
        halo.removeHandle('direction'); // default on links
        if (linkAddCallback === null) {
            halo.removeHandle('link');
        }
        const children2 = graph.getSuccessors(this.contextElement);
        if (childCount > 0) {
            const icon = children2.length > 0 ? '▲' : '▼';
            // probably don't need to show childCount when hiding
            const collapseHandle = {
                name: 'hide',
                position: ui.Halo.HandlePosition.S,
                content: `<div class="collapse-nodes mr-3" title="Hide Children">${icon}</div>`
            };
            if (children2.length > 0) {
                // there are children in the graph, so we can hide them
                halo.addHandle(collapseHandle);
            }
        }
        halo.on('action:link:add', link => {
            this.linkAddCallback(link);
        });
        halo.on('action:hide:pointerup', event => {
            event.preventDefault();
            halo.remove();
            ui.Halo.clear(paper);
            this.hideChildrenCallback(this.contextElement);
        });
        this.halo = halo;
        halo.render();
        return halo;
    }
}
