<template>
  <b-form-select
    id="ddlSelectSpecification"
    :value="value"
    :options="options"
    @input="val => $emit('input', val)"
  />
</template>

<script>
export default {
  name: 'SelectSpecificationDropDown',
  props: {
    value: {
      type: String,
      default: null,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    getDefault: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      specifications: null,
    }
  },
  computed: {
    options() {
      if (this.specifications) {
        return this.specifications
      }
      return ['Loading Specifications...']
    },
  },
  mounted() {
    this.getSpecificationList()
  },
  methods: {
    setSpecification(id) {
      this.$emit('input', id)
    },
    getSpecificationList() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/specifications/options', { params })
        .then(({ data }) => {
          this.specifications = data
          // if (this.showAll) {
          //   this.specifications = [{
          //     text: '-- All Specifications --',
          //     value: null,
          //   }, ...this.specifications]
          // } else
          if (!this.value && this.getDefault) {
            const spec = data.find(spec => spec.default)
            if (spec) {
              this.setSpecification(spec.value)
            }
          }
        })
        .catch(e => console.error(e))
    },
  },
}
</script>
