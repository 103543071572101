<template>
  <div class="canvas-container">
    <div v-if="false">
      <ontology-view :update-object="updateObject" />
    </div>
    <b-overlay
      :show="isGraphLoadingStatus"
      variant="transparent"
      :opacity="1"
      rounded="sm"
      class="canvas"
    >
      <ontology-view-joint :update-object="updateObject" @sidebar="onSidebar" />
    </b-overlay>
    <b-sidebar
      title="Entity Details"
      bg-variant="white"
      :width="mini ? '450px' : '35vw'"
      right
      :visible="isSidebarVisible"
      @hidden="isSidebarVisible = false"
    >
      <EntityDetailsSys @clicked="doUpdate()" />
    </b-sidebar>
  </div>
</template>

<script>
import OntologyView from '@/components/Domain/OntologyView.vue'
import { mapState } from 'vuex'
import EntityDetailsSys from '@/components/Domain/EntityDetailsSys.vue'
import OntologyViewJoint from '@/components/Domain/OntologyTreeViewJoint/OntologyViewJoint.vue'

export default {
  name: 'Ontology',
  components: {
    OntologyViewJoint,
    EntityDetailsSys,
    OntologyView,
  },
  data() {
    return {
      updateObject: null,
      isSidebarVisible: true,
      isGraphLoadingStatus: false,
      miniModeClass: 'mini',
      mini: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
    ...mapState({
      props: state => state.model.lookup,
    }),
    currentRouteName() {
      return this.$route.name
    },
  },
  mounted() {
  },
  methods: {
    doUpdate() {
      const nId = this.selected_entity2.context.details.id
      this.updateObject = this.selected_entity2
    },
    onSidebar(isVisible) {
      this.isSidebarVisible = isVisible
    },
  },
}
</script>

<style scoped lang="scss">
body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}
</style>
<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';
.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: 7rem;
}
.entity-pane {
  border-left: 1px dotted;
  overflow-y: auto;
}
</style>
