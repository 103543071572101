export const behaviourNodeStyleDefinitions = [
    { short: 'Cond', title: 'Selection', cssClass: 'bn bn-condition', symbol_l: '?', symbol_r: '?', radius: 12 },
    { short: 'Event', title: 'Event', cssClass: 'bn bn-event', symbol_l: '??', symbol_r: '??', radius: 24 },
    { short: 'Guard', title: 'GuardedEvent', cssClass: 'bn bn-guarded-event', symbol_l: '???', symbol_r: '???', radius: 48 },
    { short: 'State', title: 'State', cssClass: 'bn bn-state', symbol_l: '[', symbol_r: ']', radius: 0, outline: '1rem double' },
    { short: 'Input', title: 'Input', cssClass: 'bn bn-input', symbol_l: '>', symbol_r: '<', radius: 0 },
    { short: 'Output', title: 'Output', cssClass: 'bn bn-output', symbol_l: '<', symbol_r: '>', radius: 0 },
    { short: 'Assert', title: 'Assertion', cssClass: 'bn bn-assertion', symbol_l: '!', symbol_r: '!', radius: 0 },
    { short: 'Qntfn', title: 'Quantification', cssClass: 'bn bn-quantification', symbol_l: '0', symbol_r: '0', radius: 0 },
    { short: 'Qty', title: 'Quantity', cssClass: 'bn bn-quantity', symbol_l: '*', symbol_r: '*', radius: 0 },
    { short: 'Func', title: 'FunctionNode', cssClass: 'bn bn-function', symbol_l: '(', symbol_r: ')', radius: 0 },
];
