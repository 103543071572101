import { ui } from '@clientio/rappid';
function getAnchor(y, itemCount) {
    const screenY = window.innerHeight;
    return y + (itemCount * 40) > screenY ? 'bottom' : 'top';
}
export function renderMenu(tools, lastX, lastY) {
    const contextToolbar = new ui.ContextToolbar({
        target: { x: lastX, y: lastY },
        vertical: true,
        anchor: getAnchor(lastY, tools.length),
        scale: 0.9,
        tools,
    });
    contextToolbar.render();
    return contextToolbar;
}
