<template>
  <b-modal
    v-if="selected_entity && selected_entity.context"
    id="copy-entity-modal"
    :title="`Copy ${selected_entity.context.details.name}`"
    size="xl"
    class="p-0"
    @show="onShow"
    @ok="copyEntity()"
  >
    <div>
      <b-row>
        <b-col cols="9">
          <b-form-group label="Select New Parent Entity for Copy:" label-for="parent-select">
            <b-form-select
              id="parent-select"
              v-model="fields.selected_parent"
              :options="parent_opts"
              :select-size="7"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="fields.parent_rel" :aria-describedby="ariaDescribedby" name="p-rel-radios" value="aggregation">
              as aggregate child
            </b-form-radio>
            <b-form-radio v-model="fields.parent_rel" :aria-describedby="ariaDescribedby" name="p-rel-radios" value="inheritance">
              as sub-type
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Copy
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CopyEntityModal',

  data() {
    return {
      fields: {
        selected_parent: false,
        parent_rel: 'aggregation',
      },
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      components: state => state.domainModel.components,
    }),
    parent_opts() {
      return this.components.filter(cpt => cpt.id !== this.selected_entity.context.details.id).map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  methods: {
    onShow() {
      this.$store.dispatch('domainModel/getComponents')
    },
    copyEntity() {
      this.$emit(
        'copied',
        this.selected_entity.context.details.id,
        this.fields.selected_parent,
        this.fields.parent_rel === 'inheritance',
      )
    },
  },
}
</script>
