<template>
  <b-modal
    id="import-subtree-json-modal"
    title="Import component subtree from JSON"
    size="lg"
    @ok="onSubmit"
    @cancel="onReset"
    @hidden="onReset"
  >
    <div v-if="loading">
      <div class="modal-body">
        <h1 class="font-medium-3 text-center">
          Importing component(s)...
        </h1>
        <h5 class="text-center text-warning">
          <b-spinner />
        </h5>
      </div>
    </div>
    <div v-else>
      <p>
        The <span class="text-primary font-weight-bold">{{ selectedEntity2.context.details.qualified_name }}</span> component will become
        the parent of the imported component subtree.
      </p>

      <b-form-group label="Component subtree JSON file" label-for="import-components-file-selector">
        <b-form-file
          id="import-components-file-selector"
          v-model="selectedFile"
          size="lg"
          accept=".json"
          invalid-feedback="test"
          placeholder="Select JSON file"
          browse-text="Browse"
          :state="Boolean(selectedFile)"
          @input="onFileSelected"
        />
      </b-form-group>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>
      <b-button variant="success" @click="ok()">
        Import Components
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ImportSubtreeJSON',
  setup(props, context) {
    const selectedEntity2 = computed(() => store.state.domainModel.selected_entity2)
    const loading = ref(false)
    const selectedFile = ref(null)
    const fileContents = ref(null)

    const browserSupportFileUpload = () => {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    }

    const onFileSelected = () => {
      if (!browserSupportFileUpload()) {
        fileContents.value = 'The file API is not fully supported in this browser.'
      } else if (selectedFile.value && selectedFile.value.type !== 'application/json') {
        console.warn(`Invalid file type selected: ${selectedFile.value.type}`)
        selectedFile.value = null
        fileContents.value = ''
      } else if (selectedFile.value) {
        const reader = new FileReader()
        reader.readAsText(selectedFile.value)
        reader.onload = evt => { fileContents.value = evt.target.result }
      } else {
        fileContents.value = ''
      }
    }

    const onSubmit = evt => {
      evt.preventDefault()
      if (!selectedFile.value) {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select a JSON file',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      loading.value = true
      const params = { model: store.state.model.id }
      const nodeId = selectedEntity2.value.context.details.id

      axiosIns.post(
        '/api/v2/domain_model/import_subtree_json',
        { target: nodeId, file: fileContents.value },
        { params },
      )
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Imported components',
              text: 'From JSON file',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.emit('imported', nodeId)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error importing components',
              text: `${error.data}`,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          context.root.$bvModal.hide('import-subtree-json-modal')
          loading.value = false
        })
    }
    const onReset = () => {
      loading.value = false
      selectedFile.value = null
      fileContents.value = ''
    }

    return {
      selectedEntity2,

      loading,
      selectedFile,

      onFileSelected,
      onSubmit,
      onReset,
    }
  },
}
</script>
