<template>
  <b-modal
    id="delete_entity"
    title="Delete Entity"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete entity'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @ok.prevent="onSubmit"
  >
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to permanently delete this entity
            <span class="text-primary font-weight-bolder">{{ entityName }}</span>
            and
            <span class="text-primary font-weight-bolder">all its descendants, attributes and aliases</span>.
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
            If the entity or any descendants are linked to any behaviour nodes, this action will not
            continue.
          </p>
        </div>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import router from '@/router'
import store from '@/store'
import { BAlert } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { BAlert },
  setup(props, context) {
    const { modelId } = router.currentRoute.params

    const loading = ref(false)
    const parentEntityId = computed(() => store.state.domainModel.selected_entity2?.context.parent_rel.parent)
    const entityId = computed(() => store.state.domainModel.selected_entity2?.context.details.id)
    const entityName = computed(() => store.state.domainModel.selected_entity2?.context.details.name)

    const onSubmit = () => {
      axiosIns
        .delete(
          `/api/v2/domain_model/composition/${entityId.value}`,
          { params: { model: modelId } },
        )
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Entity deleted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.emit('deleted', parentEntityId.value)
        })
        .catch(error => {
          console.log(error)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cannot delete entity',
              text: 'The entity or at least one of its descendants is linked to a behaviour node, or it is a base abstract node.',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          context.root.$bvModal.hide('delete_entity')
          loading.value = false
        })
    }

    return {
      loading,
      entityName,
      onSubmit,
    }
  },
}
</script>
