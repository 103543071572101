<template>
  <b-list-group-item @click="toggleShow">
    <h6>
      {{ linkedObject.name }}
    </h6>
    <div class="mb-50">
      <b-badge
        v-if="linkedObject.requirements.length"
        pill
        variant="light-primary"
        class="font-small-1"
      >
        {{ linkedObject.requirements.length }} Requirements
      </b-badge>
      <b-badge
        v-else
        pill
        variant="light-secondary"
        class="font-small-1"
      >
        No Requirements
      </b-badge>
    </div>
    <display-list-group
      v-if="showRequirements"
      hide-header
      :items="linkedObject.requirements"
      style="max-height: 20em; overflow-y: auto; padding-left: .5em"
    >
      <template v-slot:list-group="{ items }">
        <list-group-item-entities
          v-for="(req, req_index) in items"
          :id="req"
          :key="`test-${req_index}`"
        />
      </template>
    </display-list-group>
    <small v-else-if="linkedObject.requirements.length">
      Click to view
    </small>
  </b-list-group-item>
</template>

<script>

import { BBadge } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ListGroupItemEntities from '@/components/Forms/ListGroupItems/ListGroupItemGeneral.vue'
import DisplayListGroup from '@/views/RequirementsTableLegacy/components/DisplayListGroup.vue'

export default {
  name: 'ReportListGroupItem',
  components: { DisplayListGroup, ListGroupItemEntities, BBadge },
  props: {
    linkedObject: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const showRequirements = ref(false)

    const toggleShow = () => {
      if (props.linkedObject.requirements.length) showRequirements.value = !showRequirements.value
    }

    return {
      showRequirements,

      toggleShow,
    }
  },
}
</script>
