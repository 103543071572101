import { ui } from "@clientio/rappid";
import { isArray } from 'lodash';
export class HaloExpandService {
    contextElement;
    showChildrenCallback;
    halo;
    graph;
    createHalo(node, paper, graph, showChildrenCallback, children) {
        this.contextElement = node;
        this.graph = graph;
        this.showChildrenCallback = showChildrenCallback;
        // Render the halo
        const drawnChildren = graph.getSuccessors(this.contextElement);
        const childCount = isArray(children) ? children.length : children;
        if (childCount > 0) {
            const icon = '▼';
            const showHandle = {
                name: 'show',
                position: ui.Halo.HandlePosition.SE,
                content: `<div class="collapse-nodes" title="Show Children">${icon}${childCount}</div>`
            };
            if (drawnChildren.length < childCount) {
                const cellView = paper.findViewByModel(node);
                const halo = new ui.Halo({
                    cellView,
                    boxContent: false,
                    clearAll: false,
                    clearOnBlankPointerdown: false,
                });
                halo.removeHandle('resize');
                halo.removeHandle('rotate');
                halo.removeHandle('remove');
                halo.removeHandle('fork');
                halo.removeHandle('unlink');
                halo.removeHandle('link');
                halo.removeHandle('clone');
                halo.removeHandle('direction'); // default on links
                halo.on('action:show:pointerup', event => {
                    event.preventDefault();
                    halo.remove();
                    this.showChildrenCallback(node);
                });
                halo.addHandle(showHandle);
                halo.render();
                return halo;
            }
        }
        return null;
    }
}
